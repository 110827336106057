.rot90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.00000000, M12=-1.00000000, M21=1.00000000, M22=0.00000000,sizingMethod='auto expand')";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.00000000, M12=-1.00000000, M21=1.00000000, M22=0.00000000,sizingMethod='auto expand');
  -moz-transform:  matrix(0.00000000, 1.00000000, -1.00000000, 0.00000000, 0, 0);
  -webkit-transform:  matrix(0.00000000, 1.00000000, -1.00000000, 0.00000000, 0, 0);
  -o-transform:  matrix(0.00000000, 1.00000000, -1.00000000, 0.00000000, 0, 0);
  
  -ms-transform:rotate(90deg);
  transform:rotate(90deg);
  
  -webkit-transform-origin: 0px 0px;
  -moz-transform-origin: 0px 0px;
  -ms-transform-origin: 0px 0px;
  -o-transform-origin: 0px 0px;
  transform-origin: 0px 0px;
  
  border: 0px solid;
  white-space: nowrap;
  position:absolute;
  display:inline-block;
  
  padding-bottom: 5px;

  background-color:blueviolet;
}

.rot270 {
  
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=-0.00000000, M12=1.00000000, M21=-1.00000000, M22=-0.00000000,sizingMethod='auto expand')";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=-0.00000000, M12=1.00000000, M21=-1.00000000, M22=-0.00000000,sizingMethod='auto expand');
  -moz-transform:  matrix(-0.00000000, -1.00000000, 1.00000000, -0.00000000, 0, 0);
  -webkit-transform:  matrix(-0.00000000, -1.00000000, 1.00000000, -0.00000000, 0, 0);
  -o-transform:  matrix(-0.00000000, -1.00000000, 1.00000000, -0.00000000, 0, 0);
  
  -ms-transform:rotate(270deg);
  
  -webkit-transform-origin: 0px 0px;
  -moz-transform-origin: 0px 0px;
  -ms-transform-origin: 0px 0px;
  -o-transform-origin: 0px 0px;
  transform-origin: 0px 0px;
  
  border: 0px solid;
  white-space: nowrap;
  position:absolute;
  display:inline-block;
  
  padding-bottom: 5px;
}